import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Layout from '../components/layout';

const propTypes = {
  data: PropTypes.object.isRequired,
};

const Job = ({ job }, key) => {
  return (
    <li key={key}>
      <h3>{job.jobTitle}</h3>
      <p>
        {job.startDate.slice(0, 4)} –{' '}
        {job.endDate ? job.endDate.slice(0, 4) : 'present'} {job.department},{' '}
        {job.company}
      </p>
      {documentToReactComponents(job.description.json)}
    </li>
  );
};

function IndexPage(props) {
  const jobs = props.data.jobs.edges.sort((job1, job2) => {
    const first = new Date(job1.node.startDate),
      second = new Date(job2.node.startDate);

    return first.getTime() === second.getTime() ? 0 : first < second ? 1 : -1;
  });

  return (
    <Layout>
      <h1>Curriculum Vitae</h1>
      <h2>Experience</h2>
      <ol reversed>
        {jobs.map(({ node }) => (
          <Job job={node} key={node.id} />
        ))}
      </ol>
    </Layout>
  );
}

IndexPage.propTypes = propTypes;

export default IndexPage;

export const pageQuery = graphql`
  query {
    jobs: allContentfulCvJob {
      edges {
        node {
          id
          jobTitle
          department
          company
          startDate
          endDate
          tags
          description {
            json
          }
        }
      }
    }
  }
`;
