import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <React.Fragment>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content:
                'Tom Waddington is a software engineer and development manager based in London, United Kingdom.',
            },
          ]}
        >
          <html lang="en-GB" />
        </Helmet>
        <div>{children}</div>
      </React.Fragment>
    )}
  />
);

export default Layout;
